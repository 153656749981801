import React from "react"
import { Container } from "react-bootstrap"
import { LayoutWhite } from "@layout"
import { HeroFeature2022Q3 } from "@components/hero"
import SEO from "@components/seo"
import Brands from "@components/brands"

const BrandsPage = () => {
  return (
    <LayoutWhite
      backgroundImage="animated"
      hero={
        <HeroFeature2022Q3
          title="Brand Partnership"
          subtitle="Leading Brands &amp; Agencies Partner with ATM.com&reg;"
        />
      }
    >
      <SEO title="Brand Partnerships" />
      <Container className="mt-5 mb-5 text-center">
        <div className="bg-white">
          <h2>Our Partners</h2>
          <Brands extended={true} />
          <h3>
            Over 500 brands <br />
            partnering with ATM
          </h3>
        </div>
      </Container>
    </LayoutWhite>
  )
}

export default BrandsPage
